import { create } from "zustand";
import { Server } from "./types/models";
import { useAuthStore } from "./authStore";
import { toCamel, toSnake } from "snake-camel";

type ServersStore = {
    servers: Server[] | null;
    
    fetchServers: () => Promise<void>;
    
    createServer: (server: Server) => Promise<void>;
    updateServer: (server: Server) => Promise<void>;
    deleteServer: (server: Server) => Promise<void>;
};

export const useServersStore = create<ServersStore>((set) => ({
    servers: null,
    
    fetchServers: async () => {
        const { accessToken } = useAuthStore.getState();
    
        const response = await fetch(
            `${process.env.REACT_APP_API_HOST}/servers/`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        );
    
        const servers = await response.json();
    
        set({ servers: servers.map((server: Record<string, unknown>) => toCamel(server) as Server) });
    },

    createServer: async (server: Server) => {
        const { accessToken } = useAuthStore.getState();
    
        const response = await fetch(
            `${process.env.REACT_APP_API_HOST}/servers/`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(toSnake(server)),
            },
        );
    
        const createdServer = toCamel(await response.json()) as Server;
    
        set((state) => ({ servers: [...(state.servers ?? []), createdServer] }));
    },

    updateServer: async (server: Server) => {
        const { accessToken } = useAuthStore.getState();
    
        const response = await fetch(
            `${process.env.REACT_APP_API_HOST}/servers/${server.id}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(toSnake({ ...server, user: undefined })),
            },
        );
    
        const updatedServer = toCamel(await response.json()) as Server;
    
        set((state) => ({
            servers: state.servers?.map((server) => {
                if (server.id === updatedServer.id) {
                    return updatedServer;
                }
    
                return server;
            }) ?? null,
        }));
    },

    deleteServer: async (server: Server) => {
        const { accessToken } = useAuthStore.getState();
    
        await fetch(
            `${process.env.REACT_APP_API_HOST}/servers/${server.id}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        );
    
        set((state) => ({
            servers: state.servers?.filter((s) => s.id !== server.id) ?? null,
        }));
    }
}));

