import { PencilIcon } from "@heroicons/react/24/outline";
import Modal from "../../../../components/Modal";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import { ChannelModalProps } from "./ChannelModal.types";
import { useChannelModal } from "./ChannelModal.hook";
import Select from "../../../../components/Select";

const ChannelModal: React.FC<ChannelModalProps> = ({ visible, channel, onClose }) => {
    const { register, handleSubmit, onSubmit, isAdmin, users } = useChannelModal({ visible, channel, onClose });

    return (
        <Modal
            open={visible}
            onClose={onClose}
            title={channel ? "Edit Channel" : "Create Channel"}
            icon={<PencilIcon/>}
            iconColor="yellow"
            wrapper={<form onSubmit={handleSubmit(onSubmit)} />}
        >
            <Modal.Body>
                <Input id="name" type="text" label="Name" {...register("name", { required: true })} />

                <Input id="postbackHost" type="text" label="Postback host" className="mt-4" {...register("postbackHost", { required: true })} />

                <Input id="bsAccessToken" type="text" label="BS Access Token" className="mt-4" {...register("bsAccessToken")} />

                {isAdmin() && (
                    <Select 
                        id="userId" 
                        label="User" 
                        placeholder="Select an user" 
                        className="mt-4" 
                        {...register("userId", {
                            valueAsNumber: true,
                        })}
                    >
                        {users?.map((user) => (
                            <option key={user.id} label={user.name} value={user.id} />
                        ))}
                    </Select>
                )}
            </Modal.Body>
    
            <Modal.Footer>
                <Button variant="secondary" className="mr-4" onClick={onClose} type="button">
                    Cancel
                </Button>
    
                <Button variant="success" type="submit">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ChannelModal;