import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { SignInRequest } from "../../store/types/requests";
import { useAuthStore } from "../../store/authStore";
import { useNavigate } from "react-router-dom";

export const useSignIn = () => {
    const { signIn } = useAuthStore();
    const navigate = useNavigate();

    const { register, handleSubmit } = useForm<SignInRequest>();

    const onSubmit = useCallback((request: SignInRequest) => {
        signIn(request).then(() => navigate("/"));
    }, [ signIn, navigate ]);

    return {
        register,
        handleSubmit,
        onSubmit
    };
};