import { useState, useEffect, useCallback } from "react";
import { useChannelsStore } from "../../store/channelsStore";
import { Channel } from "../../store/types/models";
import { useAuthStore } from "../../store/authStore";

export const useChannels = () => {
    const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
    const channels = useChannelsStore((state) => state.channels);
    const fetchChannels = useChannelsStore((state) => state.fetchChannels);
    const deleteChannel = useChannelsStore((state) => state.deleteChannel);

    const [isModalVisible, setModalVisible] = useState(false);
    const [editedChannel, setEditedChannel] = useState<Channel | null>(null);

    useEffect(() => {
        if(!isAuthenticated()) {
            return;
        }

        fetchChannels();
    }, [isAuthenticated]);

    const handleChannelCreate = useCallback(() => {
        setEditedChannel(null);
        setModalVisible(true);
    }, []);
    const handleChannelEdit = useCallback((channel: Channel) => () => {
        setEditedChannel(channel);
        setModalVisible(true);
    }, []);
    const handleChannelDelete = useCallback((channel: Channel) => () => {
        deleteChannel(channel);
    }, []);

    const handleModalClose = useCallback(() => {
        setModalVisible(false);
    }, []);

    return { 
        channels, 
        isModalVisible, 
        editedChannel, 
        handleChannelCreate, 
        handleChannelEdit, 
        handleChannelDelete, 
        handleModalClose 
    };
};
