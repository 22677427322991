import * as React from "react";

type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
    label?: string;
    placeholder?: string;
}

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(({ label, placeholder, className, children, ...props }, ref) => (
    <div className={className}>
        <label htmlFor={props.id} className="text-sm">
            {label}
        </label>
        
        <select ref={ref} {...props} className="border border-gray-300 rounded w-full py-2 px-1">
            {placeholder && <option selected>{placeholder}</option>}
            
            {children}
        </select>
    </div>
));

export default Select;