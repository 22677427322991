import { PencilIcon } from "@heroicons/react/24/outline";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import { CampaignModalProps } from "./CampaignModal.types";
import { useCampaignModal } from "./CampaignModal.hook";
import Select from "../../../../components/Select";
import Input from "../../../../components/Input";

const CampaignModal: React.FC<CampaignModalProps> = ({ visible, campaign, onClose }) => {
    const { register, handleSubmit, onSubmit, channels } = useCampaignModal({ visible, campaign, onClose });

    return (
        <Modal
            open={visible}
            onClose={onClose}
            title={campaign ? "Edit Campaign" : "Create Campaign"}
            icon={<PencilIcon/>}
            iconColor="yellow"
            wrapper={<form onSubmit={handleSubmit(onSubmit)} />}
        >
            <Modal.Body>
                <Input id="name" type="text" label="Name" {...register("name", { required: true })} />

                <Select 
                    id="channelId" 
                    label="Channel" 
                    placeholder="Select a channel" 
                    className="mt-4" 
                    {...register("channelId", {
                        valueAsNumber: true,
                    })}
                >
                    {channels?.map((channel) => (
                        <option key={channel.id} label={channel.name} value={channel.id} />
                    ))}
                </Select>
            </Modal.Body>
    
            <Modal.Footer>
                <Button variant="secondary" className="mr-4" onClick={onClose} type="button">
                    Cancel
                </Button>
    
                <Button variant="success" type="submit">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CampaignModal;