import { useCallback } from "react";
import { useAuthStore } from "../../store/authStore";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../../store/userStore";

export const useSidebar = () => {
    const isAdmin = useUserStore(state => state.isAdmin);
    const signOut = useAuthStore(state => state.signOut);

    const navigate = useNavigate();

    const handleSignOut = useCallback(() => {
        signOut();
        navigate("/sign-in");
    }, [signOut, navigate]);

    return {
        isAdmin,
        handleSignOut
    };
};