import * as React from "react";

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    label?: string;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ label, className, ...props }, ref) => (
    <div className={className}>
        {!!label && <label htmlFor={props.id} className="text-sm">{label}</label>}

        <input ref={ref} {...props} className="border border-gray-300 rounded w-full p-2" />
    </div>
));

export default Input;