import dayjs from "dayjs";
import Button from "../../../../components/Button";
import { serverTypes, type Server } from "../../../../store/types/models";

type ServerProps = {
    server: Server;
    onEdit: () => void;
    onDelete: () => void;

    isAdmin: () => boolean;
}

const ServerView: React.FC<ServerProps> = ({ server, onEdit, onDelete, isAdmin }) => {
    let statusColor = "bg-red-500";
    switch(true) {
    case dayjs().diff(dayjs(server.lastHealthCheck), "minute") < 1:
        statusColor = "bg-green-500";
        break;
    case dayjs().diff(dayjs(server.lastHealthCheck), "minute") < 5:
        statusColor = "bg-yellow-500";
        break;
    }

    return (
        <tr>
            <td className="w-2">
                <div className={`w-2 h-2 rounded-full ${statusColor}`}></div>
            </td>
            <td>{server.id}</td>
            {isAdmin() && (
                <td>{server.user.name}</td>   
            )}
            <td>{serverTypes[server.type]}</td>
            <td>{server.apiKey}</td>
            <td className="text-right">
                <Button variant="yellow" className="mr-4" onClick={onEdit}>
                    Edit
                </Button>
    
                <Button variant="destructive" onClick={onDelete}>
                    Delete
                </Button>
            </td>
        </tr>
    );
};

export default ServerView;