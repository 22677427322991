import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { useDashboardRouter } from "./DashboardRouter.hook";

const DashboardRouter: React.FC = () => {
    const { isAuthenticated } = useDashboardRouter();

    if(!isAuthenticated()) {
        return <Navigate to="/sign-in" replace />;
    }

    return (
        <>
            <Sidebar/>

            <main className="w-full p-16">
                <Outlet />
            </main>
        </>
    );
};

export default DashboardRouter;