import { PencilIcon } from "@heroicons/react/24/outline";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import { ServerModalProps } from "./ServerModal.types";
import { useServerModal } from "./ServerModal.hook";
import Select from "../../../../components/Select";
import { serverTypes } from "../../../../store/types/models";

const ServerModal: React.FC<ServerModalProps> = ({ visible, server, onClose }) => {
    const { register, handleSubmit, onSubmit, isAdmin, users } = useServerModal({ visible, server, onClose });

    return (
        <Modal
            open={visible}
            onClose={onClose}
            title={server ? "Edit Server" : "Add Server"}
            icon={<PencilIcon/>}
            iconColor="yellow"
            wrapper={<form onSubmit={handleSubmit(onSubmit)} />}
        >
            <Modal.Body>
                <Select 
                    id="type" 
                    label="Type" 
                    placeholder="Select a type" 
                    {...register("type")}
                >
                    {Object.entries(serverTypes).map(([key, value]) => (
                        <option key={key} label={value} value={key} />
                    ))}
                </Select>

                {isAdmin() && (
                    <Select 
                        id="userId" 
                        label="User" 
                        placeholder="Select an user" 
                        className="mt-4" 
                        {...register("userId", {
                            valueAsNumber: true,
                        })}
                    >
                        {users?.map((user) => (
                            <option key={user.id} label={user.name} value={user.id} />
                        ))}
                    </Select>
                )}
            </Modal.Body>
    
            <Modal.Footer>
                <Button variant="secondary" className="mr-4" onClick={onClose} type="button">
                    Cancel
                </Button>
    
                <Button variant="success" type="submit">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ServerModal;