import dayjs from "dayjs";
import { useEarnings } from "./Earnings.hook";
import Select from "../../components/Select";

const Earnings = () => {
    const { month, year, earnings, handleMonthChange, handleYearChange } = useEarnings();

    return (
        <>
            <div className="flex flex-row justify-center mb-4">
                <Select onChange={handleMonthChange} className="mx-2">
                    {Array.from({ length: 12 }, (_, i) => i + 1).map((m) => (
                        <option key={m} value={m} selected={m === month}>
                            {dayjs().month(m - 1).format("MMMM")}
                        </option>
                    ))}
                </Select>

                <Select onChange={handleYearChange} className="mx-2">
                    {Array.from({ length: 10 }, (_, i) => i + dayjs().year() - 5).map((y) => (
                        <option key={y} value={y} selected={y === year}>
                            {y}
                        </option>
                    ))}
                </Select>
            </div>

            <table className="table-auto">
                <thead>
                    <tr>
                        <th>Campaign</th>
                        <th>Earnings</th>
                    </tr>
                </thead>

                <tbody>
                    {earnings?.campaigns.map((campaign) => (
                        <tr key={campaign.campaignId}>
                            <td className="py-3">{campaign.campaignName}</td>
                            <td className="py-3"><span className="font-medium">${campaign.earnings}</span></td>
                        </tr>
                    ))}
                </tbody>

                <tfoot>
                    <tr>
                        <td className="py-3 font-bold text-slate-400 uppercase">Total</td>
                        <td className="py-3 font-bold">${earnings?.total}</td>
                    </tr>
                </tfoot>
            </table>
        </>
    );
};

export default Earnings;
