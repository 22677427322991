import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DashboardRouter from "./DashboardRouter";
import Home from "../views/Home";
import SignIn from "../views/SignIn";
import Channels from "../views/Channels";
import Campaigns from "../views/Campaigns";
import Users from "../views/Users";
import Servers from "../views/Servers";
import Earnings from "../views/Earnings";

const Router: React.FC = () => (
    <BrowserRouter>
        <Routes>
            <Route element={<DashboardRouter />}>
                <Route index element={<Home />} />
                <Route path="channels" element={<Channels />} />
                <Route path="campaigns" element={<Campaigns />} />
                <Route path="earnings" element={<Earnings />} />
                <Route path="servers" element={<Servers />} />
                <Route path="users" element={<Users />} />
            </Route>

            <Route path="sign-in" Component={SignIn} />
        </Routes>
    </BrowserRouter>
);

export default Router;
