import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ServerModalProps } from "./ServerModal.types";
import { useUserStore } from "../../../../store/userStore";
import { useUsersStore } from "../../../../store/usersStore";
import { Server } from "../../../../store/types/models";
import { useServersStore } from "../../../../store/serversStore";

export const useServerModal = ({ visible, server, onClose }: ServerModalProps) => {
    const isAdmin = useUserStore(state => state.isAdmin);
    const userId = useUserStore(state => state.user?.id);
    const { users, fetchUsers } = useUsersStore();
    const createServer = useServersStore(state => state.createServer);
    const updateServer = useServersStore(state => state.updateServer);

    const { register, handleSubmit, reset } = useForm<Server>({
        defaultValues: server ?? undefined,
    });

    useEffect(() => {
        if(!visible) {
            return;
        }

        reset(server ?? {
            id: 0,
            userId
        });
    }, [visible, server]);

    useEffect(() => {
        if(!isAdmin || !!users) {
            return;
        }

        fetchUsers();
    }, [users, fetchUsers]);

    const onSubmit = useCallback(async (request: Server) => {
        if(server) {
            await updateServer(request);
        } else {
            await createServer(request);
        }

        onClose();
    }, [server]);

    return {
        register,
        handleSubmit,
        onSubmit,
        isAdmin,
        users
    };
};