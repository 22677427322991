import * as React from "react";

type TextAreaProps = React.InputHTMLAttributes<HTMLTextAreaElement> & {
    label?: string;
    rows?: number;
};

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(({ label, className, ...props }, ref) => (
    <div className={className}>
        {!!label && <label htmlFor={props.id} className="text-sm">{label}</label>}

        <textarea ref={ref} {...props} className="border border-gray-300 rounded w-full p-2" />
    </div>
));

export default TextArea;