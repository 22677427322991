import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { CampaignModalProps } from "./UserModal.types";
import { User } from "../../../../store/types/models";
import { useUsersStore } from "../../../../store/usersStore";

export const useCampaignModal = ({ visible, user, onClose }: CampaignModalProps) => {
    const createUser = useUsersStore(state => state.createUser);
    const updateUser = useUsersStore(state => state.updateUser);

    const { register, handleSubmit, reset } = useForm<User>({
        defaultValues: { ...user, password: undefined } ?? undefined,
    });

    useEffect(() => {
        if(!visible) {
            return;
        }

        reset({
            ...user,
            password: undefined
        } ?? {
            id: 0
        });
    }, [visible, user]);

    const onSubmit = useCallback(async (request: User) => {
        if(user) {
            await updateUser(request);
        } else {
            await createUser(request);
        }

        onClose();
    }, [user]);

    return {
        register,
        handleSubmit,
        onSubmit
    };
};