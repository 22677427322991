import { create } from "zustand";
import { LeadsStats } from "./types/models";
import { useAuthStore } from "./authStore";
import { toCamel } from "snake-camel";
import { Lead } from "./types/models";

type LeadsStore = {
  leadsStats: LeadsStats | null;
  last: Lead[] | null;

  fetchLeadsStats: () => Promise<void>;
  fetchLastLeads: () => Promise<void>;
};

export const useLeadsStore = create<LeadsStore>((set) => ({
    leadsStats: null,
    last: null,

    fetchLeadsStats: async () => {
        const { accessToken } = useAuthStore.getState();

        const response = await fetch(
            `${process.env.REACT_APP_API_HOST}/leads/stats`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        );

        const leadsStats = await response.json();

        set({
            leadsStats: toCamel(leadsStats) as LeadsStats,
        });
    },

    fetchLastLeads: async () => {
        const { accessToken } = useAuthStore.getState();

        const response = await fetch(
            `${process.env.REACT_APP_API_HOST}/leads/last`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        );

        const last: Lead[] = (await response.json()).map((lead: Record<string, unknown>) => toCamel(lead) as Lead);

        set({
            last: last.map(({ createdAt, ...rest }) => ({
                createdAt: new Date(createdAt),
                ...rest,
            })),
        });
    }
}));