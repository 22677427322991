import * as React from "react";
import { useHome } from "./Home.hook";
import cx from "classnames";
import dayjs from "dayjs";

const Home: React.FC = () => {
    const { leadsStats, lastLeads } = useHome();

    return (
        <>
            <div className="flex flex-row justify-around">
                <div className="w-64 h-40 p-2 flex flex-col items-center bg-slate-800 rounded-lg">
                    <div className="flex flex-1 justify-center items-center">
                        <span className="text-5xl text-white font-bold">
                            {leadsStats?.count}
                        </span>
                    </div>

                    <span className="justify-self-end text-slate-400 text-sm font-semibold uppercase">Today&apos;s leads</span>
                </div>

                <div className="w-64 h-40 p-2 flex flex-col items-center bg-slate-800 rounded-lg">
                    <div className="flex flex-1 justify-center items-center">
                        <span className="text-5xl text-white font-bold">
                            ${leadsStats?.earnings}
                        </span>
                    </div>

                    <span className="justify-self-end text-slate-400 text-sm font-semibold uppercase">Today&apos;s earnings</span>
                </div>
            </div>

            <div className="mt-16">
                <h1 className="text-sm font-semibold text-slate-200 uppercase ml-4 mb-2">
                    Last 10 leads
                </h1>

                <table className="table-auto">
                    <thead>
                        <tr>
                            <th>IP</th>
                            <th>Payout</th>
                            <th>Campaign</th>
                            <th>Date</th>
                            <th>User agent</th>
                        </tr>
                    </thead>

                    <tbody>
                        {lastLeads?.map((lead, i) => (
                            <tr key={lead.id} className={
                                cx(
                                    "h-14", 
                                    i % 2 === 0 && "bg-slate-700 bg-opacity-50"
                                )
                            }>
                                <td>{lead.ip}</td>
                                <td>{lead.payout} {lead.currency}</td>
                                <td>{lead.campaign.name}</td>
                                <td>{dayjs(lead.createdAt).format("DD/MM/YYYY HH:mm:ss")}</td>
                                <td>
                                    {lead.userAgent && (
                                        <div className="p-2 rounded bg-slate-900">
                                            <code className="w-52 line-clamp-1" title={lead.userAgent}>{lead.userAgent}</code>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Home;