import * as React from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Body from "./Body";
import Footer from "./Footer";

type ModalProps = {
    open: boolean;
    onClose: () => void;
    title?: string;
    subtitle?: string;
    children: React.ReactElement<React.PropsWithChildren, typeof Body> | [
        React.ReactElement<React.PropsWithChildren, typeof Body>,
        React.ReactElement<React.PropsWithChildren, typeof Footer>
    ]
    wrapper?: React.ReactElement;
} & ({
    icon?: undefined;
    iconColor?: undefined;
} | {
    icon: React.ReactElement;
    iconColor: IconColor;
})

type IconColor = "blue" | "yellow" | "red";

const iconBgColors: Record<IconColor, string> = {
    blue: "bg-blue-100",
    yellow: "bg-yellow-200",
    red: "bg-red-100",
};

const iconColors: Record<IconColor, string> = {
    blue: "text-blue-600",
    yellow: "text-yellow-600",
    red: "text-red-600",
};

const Modal: React.FC<React.PropsWithChildren<ModalProps>> & {
    Body: typeof Body;
    Footer: typeof Footer;
} = ({ open, onClose, title, subtitle, icon, iconColor, children, wrapper }) => {
    const initialFocusRef = React.useRef<HTMLButtonElement>(null);

    return (
        <Transition.Root show={open} as={Fragment}>
            
            <Dialog as="div" className="relative z-10" initialFocus={initialFocusRef} onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-slate-900 bg-opacity-75 transition-opacity" />
                </Transition.Child>
    
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                {React.cloneElement(wrapper ?? <div/>, {
                                    ...wrapper?.props,
                                }, (
                                    <>
                                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                            <div className="flex flex-row">
                                                {icon && (
                                                    <div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${iconBgColors[iconColor]} sm:mx-0 sm:h-10 sm:w-10 mb-3`}>
                                                        {React.cloneElement(icon, { className: `h-6 w-6 ${iconColors[iconColor]}` })}
                                                    </div>
                                                )}

                                                <div className="ml-4 flex-auto">
                                                    {title && (
                                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 mb-2">
                                                            {title}
                                                        </Dialog.Title> 
                                                    )}

                                                    {subtitle && (
                                                        <div className="mb-2">
                                                            <p className="text-sm text-gray-500">
                                                    Are you sure you want to deactivate your account? All of your data will be permanently
                                                    removed. This action cannot be undone.
                                                            </p>
                                                        </div>
                                                    )}

                                                    {children instanceof Array ? children[0] : children}
                                                </div>
                                            </div>
                                        </div>

                                        {children instanceof Array && children[1] && (
                                            <div className="bg-slate-100 py-3 px-6 flex flex-row-reverse">
                                                <div>
                                                    {children[1]}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ))}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;