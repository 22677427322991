import { PencilIcon } from "@heroicons/react/24/outline";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import { CampaignModalProps } from "./UserModal.types";
import { useCampaignModal as useUserModal } from "./UserModal.hook";
import Input from "../../../../components/Input";

const UserModal: React.FC<CampaignModalProps> = ({ visible, user, onClose }) => {
    const { register, handleSubmit, onSubmit } = useUserModal({ visible, user, onClose });

    return (
        <Modal
            open={visible}
            onClose={onClose}
            title={user ? "Edit User" : "Create User"}
            icon={<PencilIcon/>}
            iconColor="yellow"
            wrapper={<form onSubmit={handleSubmit(onSubmit)} />}
        >
            <Modal.Body>
                <Input id="name" type="text" label="Name" {...register("name", { required: true })} />

                <Input id="password" type="password" label="Password" className="mt-4" {...register("password", { required: true })} />
            </Modal.Body>
    
            <Modal.Footer>
                <Button variant="secondary" className="mr-4" onClick={onClose} type="button">
                    Cancel
                </Button>
    
                <Button variant="success" type="submit">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UserModal;