import { create } from "zustand";
import { Campaign } from "./types/models";
import { useAuthStore } from "./authStore";
import { toCamel, toSnake } from "snake-camel";

type CampaignsStore = {
  campaigns: Campaign[] | null;

  fetchCampaigns: () => Promise<void>;

  createCampaign: (campaign: Campaign) => Promise<void>;
  updateCampaign: (campaign: Campaign) => Promise<void>;
  deleteCampaign: (campaign: Campaign) => Promise<void>;
};

export const useCampaignsStore = create<CampaignsStore>((set) => ({
    campaigns: null,

    fetchCampaigns: async () => {
        const { accessToken } = useAuthStore.getState();

        const response = await fetch(
            `${process.env.REACT_APP_API_HOST}/campaigns/`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        );

        const campaigns = await response.json();

        set({
            campaigns: campaigns.map(
                (campaign: Record<string, unknown>) => toCamel(campaign) as Campaign,
            ),
        });
    },

    createCampaign: async (campaign: Campaign) => {
        const { accessToken } = useAuthStore.getState();

        const response = await fetch(
            `${process.env.REACT_APP_API_HOST}/campaigns/`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(toSnake(campaign)),
            },
        );

        const createdCampaign = toCamel(await response.json()) as Campaign;

        set((state) => ({ campaigns: [...(state.campaigns ?? []), createdCampaign] }));
    },

    updateCampaign: async (campaign: Campaign) => {
        const { accessToken } = useAuthStore.getState();

        const response = await fetch(
            `${process.env.REACT_APP_API_HOST}/campaigns/${campaign.id}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(toSnake(campaign)),
            },
        );

        const updatedCampaign = toCamel(await response.json()) as Campaign;

        set((state) => ({
            campaigns: state.campaigns?.map((c) =>
                c.id === updatedCampaign.id ? updatedCampaign : c,
            ),
        }));
    },

    deleteCampaign: async (campaign: Campaign) => {
        const { accessToken } = useAuthStore.getState();

        await fetch(
            `${process.env.REACT_APP_API_HOST}/campaigns/${campaign.id}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        );

        set((state) => ({
            campaigns: state.campaigns?.filter(
                (c) => c.id !== campaign.id,
            ),
        }));
    }
}));