import { create } from "zustand";
import { User } from "./types/models";
import { useAuthStore } from "./authStore";
import { toCamel } from "snake-camel";

type UserStore = {
    isLoading: boolean,
    user: User | null;

    isAdmin: () => boolean;

    fetchUser: () => Promise<void>;
}

export const useUserStore = create<UserStore>((set, get) => ({
    isLoading: false,
    user: null,

    isAdmin: () => get().user?.isAdmin ?? false,

    fetchUser: async () => {
        if (get().isLoading) {
            return;
        }

        set({ isLoading: true });

        const { accessToken } = useAuthStore.getState();

        try {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/user`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}` 
                },
            });

            if(response.status === 401) {
                useAuthStore.getState().signOut();
                return;
            }
    
            const user = toCamel(await response.json()) as User;
    
            set({ user });
        } finally {
            set({ isLoading: false });
        }
    }
}));