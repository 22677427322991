import * as React from "react";
import { useUsers } from "./Users.hook";
import Button from "../../components/Button";
import UserModal from "./components/UserModal";

const Channels: React.FC = () => {
    const { 
        users, 
        isModalVisible,
        selectedUser,
        handleUserCreate,
        handleUserEdit,
        handleUserDelete,
        handleModalClose
    } = useUsers();

    return (
        <>
            <div className="flex flex-row justify-end">
                <Button variant="success" className="mb-4" onClick={handleUserCreate}>
                    Create user
                </Button>
            </div>

            <table className="table-auto">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th/>
                    </tr>
                </thead>

                <tbody>
                    {users?.map((user) => (
                        <tr key={user.id}>
                            <td>{user.id}</td>
                            <td>{user.name}</td>
                            <td className="text-right">
                                <Button variant="yellow" className="mr-4" onClick={handleUserEdit(user)}>
                                    Edit
                                </Button>

                                <Button variant="destructive" onClick={handleUserDelete(user)}>
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <UserModal
                visible={isModalVisible}
                user={selectedUser}
                onClose={handleModalClose}
            />
        </>
    );
};

export default Channels;