import * as React from "react";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { useSignIn } from "./SignIn.hook";

const SignIn: React.FC = () => {
    const { register, handleSubmit, onSubmit } = useSignIn();

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="w-full h-screen flex flex-col justify-center items-center">
            <div className="bg-white p-16 rounded">
                <Input id="name" type="text" label="Name" className="mb-4" {...register("name", { required: true })} />

                <Input id="password" type="password" label="Password" className="mb-8" {...register("password", { required: true })} />

                <div className="flex justify-center">
                    <Button type="submit" variant="primary">
                        Sign in
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default SignIn;