import * as React from "react";
import Styles from "./Button.module.scss";
import cx from "classnames";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: "primary" | "secondary" | "success" | "destructive" | "yellow";
    children: string;
}

const Button: React.FC<ButtonProps> = ({ variant = "primary", className, children, ...props }) => {
    return (
        <button className={cx(Styles.button, Styles[variant], className)} {...props}>
            {children}
        </button>
    );
};

export default Button;