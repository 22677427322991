import { useState, useEffect, useCallback, useRef } from "react";
import { Server } from "../../store/types/models";
import { useAuthStore } from "../../store/authStore";
import { useServersStore } from "../../store/serversStore";
import { useUserStore } from "../../store/userStore";

export const useChannels = () => {
    const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
    const isAdmin = useUserStore((state) => state.isAdmin);
    const servers = useServersStore((state) => state.servers);
    const fetchServers = useServersStore((state) => state.fetchServers);
    const deleteServer = useServersStore((state) => state.deleteServer);

    const interval = useRef<number | null>(null);

    const [isModalVisible, setModalVisible] = useState(false);
    const [editedServer, setEditedServer] = useState<Server | null>(null);

    useEffect(() => {
        if(!isAuthenticated()) {
            return;
        }

        fetchServers();

        interval.current = window.setInterval(() => {
            fetchServers();
        }, 10000);

        return () => {
            if(interval.current) {
                window.clearInterval(interval.current);
            }
        };
    }, [isAuthenticated]);

    const handleServerCreate = useCallback(() => {
        setEditedServer(null);
        setModalVisible(true);
    }, []);
    const handleServerEdit = useCallback((server: Server) => () => {
        setEditedServer(server);
        setModalVisible(true);
    }, []);
    const handleServerDelete = useCallback((server: Server) => () => {
        deleteServer(server);
    }, []);

    const handleModalClose = useCallback(() => {
        setModalVisible(false);
    }, []);

    return { 
        isAdmin,
        servers, 
        isModalVisible, 
        editedServer, 
        handleServerCreate, 
        handleServerEdit, 
        handleServerDelete, 
        handleModalClose 
    };
};
