import { useState, useEffect, useCallback } from "react";
import { useAuthStore } from "../../store/authStore";
import { useUsersStore } from "../../store/usersStore";
import { User } from "../../store/types/models";

export const useUsers = () => {
    const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
    const users = useUsersStore((state) => state.users);
    const fetchUsers = useUsersStore((state) => state.fetchUsers);
    const deleteUser = useUsersStore((state) => state.deleteUser);

    const [isModalVisible, setModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);

    useEffect(() => {
        if(!isAuthenticated()) {
            return;
        }

        fetchUsers();
    }, [isAuthenticated]);

    const handleUserCreate = useCallback(() => {
        setSelectedUser(null);
        setModalVisible(true);
    }, []);
    const handleUserEdit = useCallback((user: User) => () => {
        setSelectedUser(user);
        setModalVisible(true);
    }, []);
    const handleUserDelete = useCallback((user: User) => () => () => {
        deleteUser(user);
    }, []);

    const handleModalClose = useCallback(() => {
        setModalVisible(false);
    }, []);

    return {
        users, 
        isModalVisible, 
        selectedUser, 
        handleUserCreate, 
        handleUserEdit, 
        handleUserDelete, 
        handleModalClose 
    };
};
