import { create } from "zustand";
import { persist } from "zustand/middleware";
import { SignInRequest } from "./types/requests";

type AuthStore = {
    accessToken: string | null;

    isAuthenticated: () => boolean;
    signIn: (request: SignInRequest) => Promise<void>;
    signOut: () => void;
}

export const useAuthStore = create<AuthStore>()(
    persist(
        (set, get) => ({
            accessToken: null,

            isAuthenticated: () => !!get().accessToken,
        
            signIn: async (request) => {
                const response = await fetch(`${process.env.REACT_APP_API_HOST}/auth/sign-in`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(request)
                });
        
                const { access_token: accessToken } = await response.json();
        
                set({ accessToken });
            },

            signOut: () => {
                set({ accessToken: null });
            }
        }),
        {
            name: "auth",
            version: 1
        }
    )
);