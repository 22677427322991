import { create } from "zustand";
import { Channel } from "./types/models";
import { useAuthStore } from "./authStore";
import { toCamel, toSnake } from "snake-camel";

type ChannelsStore = {
  channels: Channel[] | null;

  fetchChannels: () => Promise<void>;

  createChannel: (channel: Channel) => Promise<void>;
  updateChannel: (channel: Channel) => Promise<void>;
  deleteChannel: (channel: Channel) => Promise<void>;
};

export const useChannelsStore = create<ChannelsStore>((set) => ({
    channels: null,
    
    fetchChannels: async () => {
        const { accessToken } = useAuthStore.getState();
    
        const response = await fetch(
            `${process.env.REACT_APP_API_HOST}/channels/`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        );
    
        const channels = await response.json();
    
        set({ channels: channels.map((channel: Record<string, unknown>) => toCamel(channel) as Channel) });
    },

    createChannel: async (channel: Channel) => {
        const { accessToken } = useAuthStore.getState();
    
        const response = await fetch(
            `${process.env.REACT_APP_API_HOST}/channels/`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(toSnake(channel)),
            },
        );
    
        const createdChannel = toCamel(await response.json()) as Channel;
    
        set((state) => ({ channels: [...(state.channels ?? []), createdChannel] }));
    },

    updateChannel: async (channel: Channel) => {
        const { accessToken } = useAuthStore.getState();
    
        const response = await fetch(
            `${process.env.REACT_APP_API_HOST}/channels/${channel.id}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(toSnake(channel)),
            },
        );
    
        const updatedChannel = toCamel(await response.json()) as Channel;
    
        set((state) => ({
            channels: state.channels?.map((channel) =>
                channel.id === updatedChannel.id ? updatedChannel : channel,
            ),
        }));
    },

    deleteChannel: async (channel: Channel) => {
        const { accessToken } = useAuthStore.getState();
    
        await fetch(
            `${process.env.REACT_APP_API_HOST}/channels/${channel.id}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        );
    
        set((state) => ({
            channels: state.channels?.filter(
                (c) => c.id !== channel.id,
            ),
        }));
    }
}));