import * as React from "react";
import { useEffect, useCallback } from "react";
import { useEarningsStore } from "../../store/earningsStore";

export const useEarnings = () => {
    const month = useEarningsStore((state) => state.month);
    const year = useEarningsStore((state) => state.year);
    const earnings = useEarningsStore((state) => state.earnings);
    const fetchEarnings = useEarningsStore((state) => state.fetchEarnings);

    useEffect(() => {
        fetchEarnings(month, year);
    }, []);

    const handleMonthChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        fetchEarnings(parseInt(e.target.value), year);
    }, [year]);

    const handleYearChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        fetchEarnings(month, parseInt(e.target.value));
    }, [month]);

    return {
        month,
        year,
        earnings,

        handleMonthChange,
        handleYearChange,
    };
};