import * as React from "react";
import { useChannels } from "./Channels.hook";
import Button from "../../components/Button";
import ChannelModal from "./components/ChannelModal";

const Channels: React.FC = () => {
    const { channels, isModalVisible, editedChannel, handleChannelCreate, handleChannelEdit, handleChannelDelete, handleModalClose } = useChannels();

    return (
        <>
            <div className="flex flex-row justify-end">
                <Button variant="success" className="mb-4" onClick={handleChannelCreate}>
                    Create channel
                </Button>
            </div>

            <table className="table-auto">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th/>
                    </tr>
                </thead>

                <tbody>
                    {channels?.map((channel) => (
                        <tr key={channel.id}>
                            <td>{channel.id}</td>
                            <td>{channel.name}</td>
                            <td className="text-right">
                                <Button variant="yellow" className="mr-4" onClick={handleChannelEdit(channel)}>
                                    Edit
                                </Button>

                                <Button variant="destructive" onClick={handleChannelDelete(channel)}>
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <ChannelModal
                visible={isModalVisible}
                channel={editedChannel}
                onClose={handleModalClose}
            />
        </>
    );
};

export default Channels;