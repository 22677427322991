import * as React from "react";
import { useChannels } from "./Servers.hook";
import Button from "../../components/Button";
import ServerModal from "./components/ServerModal";
import Server from "./components/Server";

const Channels: React.FC = () => {
    const { isAdmin, servers, isModalVisible, editedServer, handleServerCreate, handleServerEdit, handleServerDelete, handleModalClose } = useChannels();

    return (
        <>
            <div className="flex flex-row justify-end">
                <Button variant="success" className="mb-4" onClick={handleServerCreate}>
                    Add server
                </Button>
            </div>

            <table className="table-auto">
                <thead>{}
                    <tr>
                        <th></th>
                        <th>ID</th>
                        {isAdmin() && (
                            <th>User</th>
                        )}
                        <th>Type</th>
                        <th>API key</th>
                        <th/>
                    </tr>
                </thead>

                <tbody>
                    {servers?.map((server) => (
                        <Server 
                            key={server.id} 
                            server={server} 
                            onEdit={handleServerEdit(server)} 
                            onDelete={handleServerDelete(server)} 
                            isAdmin={isAdmin} 
                        />
                    ))}
                </tbody>
            </table>

            <ServerModal
                visible={isModalVisible}
                server={editedServer}
                onClose={handleModalClose}
            />
        </>
    );
};

export default Channels;