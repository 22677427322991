import * as React from "react";
import { useCampaigns } from "./Campaigns.hook";
import Button from "../../components/Button";
import CampaignModal from "./components/CampaignModal";
import PostbackLinkModal from "./components/PostbackLinkModal";

const Channels: React.FC = () => {
    const { 
        campaigns, 
        isPostbackLinkModalVisible,
        isCampaignModalVisible, 
        selectedCampaign, 
        handlePostbackLinkModalOpen,
        handleCampaignCreate, 
        handleCampaignEdit, 
        handleCampaignDelete, 
        handlePostbackLinkModalClose,
        handleCampaignModalClose 
    } = useCampaigns();

    return (
        <>
            <div className="flex flex-row justify-end">
                <Button variant="success" className="mb-4" onClick={handleCampaignCreate}>
                    Create campaign
                </Button>
            </div>

            <table className="table-auto">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th/>
                    </tr>
                </thead>

                <tbody>
                    {campaigns?.map((campaign) => (
                        <tr key={campaign.id}>
                            <td>{campaign.id}</td>
                            <td>{campaign.name}</td>
                            <td className="text-right">
                                <Button variant="primary" className="mr-4" onClick={handlePostbackLinkModalOpen(campaign)}>
                                    Postback link
                                </Button>

                                <Button variant="yellow" className="mr-4" onClick={handleCampaignEdit(campaign)}>
                                    Edit
                                </Button>

                                <Button variant="destructive" onClick={handleCampaignDelete(campaign)}>
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <PostbackLinkModal
                visible={isPostbackLinkModalVisible}
                campaign={selectedCampaign}
                onClose={handlePostbackLinkModalClose}
            />

            <CampaignModal
                visible={isCampaignModalVisible}
                campaign={selectedCampaign}
                onClose={handleCampaignModalClose}
            />
        </>
    );
};

export default Channels;