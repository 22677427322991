import * as React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import Styles from "./Link.module.scss";

type LinkProps = NavLinkProps & {
    icon: React.ReactElement;
}

const Link: React.FC<LinkProps> = ({ icon, children, ...props }) => (
    <NavLink className={Styles.link} {...props}>
        <span className="mr-2">
            {React.cloneElement(icon, {
                className: "w-6 h-6"
            })}
        </span>

        {children as React.ReactNode}
    </NavLink>
);

export default Link;