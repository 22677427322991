import { useState, useEffect, useCallback } from "react";
import { Campaign } from "../../store/types/models";
import { useAuthStore } from "../../store/authStore";
import { useCampaignsStore } from "../../store/campaignsStore";

export const useCampaigns = () => {
    const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
    const campaigns = useCampaignsStore((state) => state.campaigns);
    const fetchCampaigns = useCampaignsStore((state) => state.fetchCampaigns);
    const deleteCampaign = useCampaignsStore((state) => state.deleteCampaign);

    const [isPostbackLinkModalVisible, setPostbackLinkModalVisible] = useState(false);
    const [isCampaignModalVisible, setCampaignModalVisible] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(null);

    useEffect(() => {
        if(!isAuthenticated()) {
            return;
        }

        fetchCampaigns();
    }, [isAuthenticated]);

    const handlePostbackLinkModalOpen = useCallback((campaign: Campaign) => () => {
        setSelectedCampaign(campaign);
        setPostbackLinkModalVisible(true);
    }, []);

    const handleCampaignCreate = useCallback(() => {
        setSelectedCampaign(null);
        setCampaignModalVisible(true);
    }, []);
    const handleCampaignEdit = useCallback((campaign: Campaign) => () => {
        setSelectedCampaign(campaign);
        setCampaignModalVisible(true);
    }, []);
    const handleCampaignDelete = useCallback((campaign: Campaign) => () => {
        deleteCampaign(campaign);
    }, []);

    const handlePostbackLinkModalClose = useCallback(() => {
        setPostbackLinkModalVisible(false);
    }, []);
    const handleCampaignModalClose = useCallback(() => {
        setCampaignModalVisible(false);
    }, []);

    return {
        campaigns, 
        isPostbackLinkModalVisible,
        isCampaignModalVisible, 
        selectedCampaign, 
        handlePostbackLinkModalOpen,
        handleCampaignCreate, 
        handleCampaignEdit, 
        handleCampaignDelete, 
        handlePostbackLinkModalClose,
        handleCampaignModalClose 
    };
};
