import { create } from "zustand";
import { Earnings } from "./types/models";
import { useAuthStore } from "./authStore";
import { toCamel } from "snake-camel";
import dayjs from "dayjs";

type EarningsStore = {
    month: number;
    year: number;
    earnings: Earnings | null;
    
    fetchEarnings: (month: number, year: number) => Promise<void>;
};

export const useEarningsStore = create<EarningsStore>((set) => ({
    month: dayjs().month() + 1,
    year: dayjs().year(),
    earnings: null,

    fetchEarnings: async (month, year) => {
        set({
            month,
            year,
        });

        const { accessToken } = useAuthStore.getState();

        const response = await fetch(
            `${process.env.REACT_APP_API_HOST}/leads/earnings?month=${month}&year=${year}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        );

        const earnings = await response.json();

        set({
            earnings: toCamel(earnings) as Earnings,
        });
    },
}));