import { User } from "./user";

export type Server = {
    id: number;
    user: User;
    userId: number;
    apiKey: string;
    type: Type;
    lastHealthCheck: string;
}

export type Type = keyof typeof serverTypes;

export const serverTypes = {
    link_clicker: "Link Clicker",
    game_worker: "Game Worker",
    proxy_fetcher: "Proxy Fetcher"
};