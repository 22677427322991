import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { CampaignModalProps } from "./CampaignModal.types";
import { useUserStore } from "../../../../store/userStore";
import { Campaign } from "../../../../store/types/models";
import { useCampaignsStore } from "../../../../store/campaignsStore";
import { useChannelsStore } from "../../../../store/channelsStore";

export const useCampaignModal = ({ visible, campaign, onClose }: CampaignModalProps) => {
    const isAdmin = useUserStore(state => state.isAdmin);
    const { channels, fetchChannels } = useChannelsStore();
    const createCampaign = useCampaignsStore(state => state.createCampaign);
    const updateCampaign = useCampaignsStore(state => state.updateCampaign);

    const { register, handleSubmit, reset } = useForm<Campaign>({
        defaultValues: campaign ?? undefined,
    });

    useEffect(() => {
        if(!visible) {
            return;
        }

        reset(campaign ?? {
            id: 0,
            channelId: undefined
        });
    }, [visible, campaign]);

    useEffect(() => {
        if(channels) {
            return;
        }

        fetchChannels();
    }, [channels, fetchChannels]);

    const onSubmit = useCallback(async (request: Campaign) => {
        if(campaign) {
            await updateCampaign(request);
        } else {
            await createCampaign(request);
        }

        onClose();
    }, [campaign]);

    return {
        register,
        handleSubmit,
        onSubmit,
        isAdmin,
        channels
    };
};