import { useEffect } from "react";
import { useAuthStore } from "../../store/authStore";
import { useUserStore } from "../../store/userStore";

export const useDashboardRouter = () => {
    const isAuthenticated = useAuthStore(state => state.isAuthenticated);
    const user = useUserStore(state => state.user);
    const fetchUser = useUserStore(state => state.fetchUser);

    useEffect(() => {
        if(!isAuthenticated() || !!user) {
            return;
        }

        fetchUser();
    }, [isAuthenticated, user, fetchUser]);

    return {
        isAuthenticated
    };
};