import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ChannelModalProps } from "./ChannelModal.types";
import { useUserStore } from "../../../../store/userStore";
import { useUsersStore } from "../../../../store/usersStore";
import { useChannelsStore } from "../../../../store/channelsStore";
import { Channel } from "../../../../store/types/models";

export const useChannelModal = ({ visible, channel, onClose }: ChannelModalProps) => {
    const isAdmin = useUserStore(state => state.isAdmin);
    const userId = useUserStore(state => state.user?.id);
    const { users, fetchUsers } = useUsersStore();
    const createChannel = useChannelsStore(state => state.createChannel);
    const updateChannel = useChannelsStore(state => state.updateChannel);

    const { register, handleSubmit, reset } = useForm<Channel>({
        defaultValues: channel ?? undefined,
    });

    useEffect(() => {
        if(!visible) {
            return;
        }

        reset(channel ?? {
            id: 0,
            name: undefined,
            userId
        });
    }, [visible, channel]);

    useEffect(() => {
        if(!isAdmin || !!users) {
            return;
        }

        fetchUsers();
    }, [users, fetchUsers]);

    const onSubmit = useCallback(async (request: Channel) => {
        if(channel) {
            await updateChannel(request);
        } else {
            await createChannel(request);
        }

        onClose();
    }, [channel]);

    return {
        register,
        handleSubmit,
        onSubmit,
        isAdmin,
        users
    };
};