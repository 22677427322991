import { create } from "zustand";
import { User } from "./types/models";
import { useAuthStore } from "./authStore";
import { toCamel } from "snake-camel";

type UserStore = {
    users: User[] | null;

    fetchUsers: () => Promise<void>;

    createUser: (user: User) => Promise<void>;
    updateUser: (user: User) => Promise<void>;
    deleteUser: (user: User) => Promise<void>;
}

export const useUsersStore = create<UserStore>((set) => ({
    users: null,

    fetchUsers: async () => {
        const { accessToken } = useAuthStore.getState();

        const response = await fetch(
            `${process.env.REACT_APP_API_HOST}/users/`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        );

        const users = toCamel(await response.json()) as User[];

        set({ users });
    },

    createUser: async (user: User) => {
        const { accessToken } = useAuthStore.getState();

        const response = await fetch(
            `${process.env.REACT_APP_API_HOST}/users/`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(user),
            },
        );

        const createdUser = toCamel(await response.json()) as User;

        set((state) => ({ users: [...(state.users ?? []), createdUser] }));
    },

    updateUser: async (user: User) => {
        const { accessToken } = useAuthStore.getState();

        const response = await fetch(
            `${process.env.REACT_APP_API_HOST}/users/${user.id}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(user),
            },
        );

        const updatedUser = toCamel(await response.json()) as User;

        set((state) => ({
            users: state.users?.map((u) => u.id === updatedUser.id ? updatedUser : u) ?? [],
        }));
    },

    deleteUser: async (user: User) => {
        const { accessToken } = useAuthStore.getState();

        await fetch(
            `${process.env.REACT_APP_API_HOST}/users/${user.id}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        );

        set((state) => ({
            users: state.users?.filter((u) => u.id !== user.id) ?? [],
        }));
    },
}));