import { LinkIcon } from "@heroicons/react/24/outline";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import { Campaign } from "../../../../store/types/models";
import TextArea from "../../../../components/TextArea";

type PostbackLinkModalProps = {
    visible: boolean;
    campaign: Campaign | null;
    onClose: () => void;
};

const PostbackLinkModal: React.FC<PostbackLinkModalProps> = ({ visible, campaign, onClose }) => {
    return (
        <Modal 
            open={visible} 
            onClose={onClose} 
            title={campaign?.name}
            icon={<LinkIcon/>} 
            iconColor="blue"
        >
            <Modal.Body>
                <TextArea 
                    id="postbackLink" 
                    type="text" 
                    label="Postback link" 
                    value={`${campaign?.channel.postbackHost}/postback?hash=${campaign?.postbackHash}&ip={ip}&user_agent={user_agent}&payout={payout}&currency={currency}`} 
                    rows={6}
                    readOnly 
                />
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={onClose} type="button">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PostbackLinkModal;