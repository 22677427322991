import Link from "./Link";
import { HomeIcon, NewspaperIcon, ServerStackIcon, TvIcon, UserGroupIcon, ArrowRightOnRectangleIcon, BanknotesIcon } from "@heroicons/react/24/outline";
import { useSidebar } from "./Sidebar.hook";

const Sidebar: React.FC = () => {
    const { isAdmin, handleSignOut } = useSidebar();
    
    return (
        <nav className="sticky top-0 w-72 h-screen px-6 pt-32 pb-16 flex flex-col bg-indigo-800">
            <Link icon={<HomeIcon />} to="/">
                Dashboard
            </Link>

            <Link icon={<TvIcon />} to="/channels">
                Channels
            </Link>

            <Link icon={<NewspaperIcon />} to="/campaigns">
                Campaigns
            </Link>

            <Link icon={<BanknotesIcon />} to="/earnings">
                Earnings
            </Link>

            <Link icon={<ServerStackIcon />} to="/servers">
                Servers
            </Link>

            {isAdmin() && (                                               
                <Link icon={<UserGroupIcon />} to="/users">
                    Users
                </Link>
            )}

            <div className="flex-1" />

            <button className="w-full px-4 py-3 flex items-center rounded text-sm font-semibold text-indigo-200" onClick={handleSignOut}>
                <span className="mr-2">
                    <ArrowRightOnRectangleIcon className="w-6 h-6" />
                </span>

                Logout
            </button>
        </nav>
    );
};

export default Sidebar;