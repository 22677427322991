import { useEffect, useRef } from "react";
import { useLeadsStore } from "../../store/leadsStore";

export const useHome = () => {
    const leadsStats = useLeadsStore((state) => state.leadsStats);
    const lastLeads = useLeadsStore((state) => state.last);
    const fetchLeadsStats = useLeadsStore((state) => state.fetchLeadsStats);
    const fetchLastLeads = useLeadsStore((state) => state.fetchLastLeads);

    const interval = useRef<number | null>(null);

    useEffect(() => {
        fetchLeadsStats();
        fetchLastLeads();

        interval.current = window.setInterval(() => {
            fetchLeadsStats();
            fetchLastLeads();
        }, 60000);

        return () => {
            if (interval.current) {
                window.clearInterval(interval.current);
            }
        };
    }, [fetchLeadsStats, fetchLastLeads]);

    return { leadsStats, lastLeads };
};